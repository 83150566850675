import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PageNotFoundComponent implements OnInit {
  homeUrl?: string;
  constructor() {
    this.homeUrl = '/';
  }

  ngOnInit(): void {
    this.homeUrl = '/';
  }
}

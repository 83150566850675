import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-advisor-matching-tool',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './advisor-matching-tool.component.html',
  styleUrl: './advisor-matching-tool.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AdvisorMatchingToolComponent {}

import { Injectable, Inject } from '@angular/core';

import { UrlBuilder } from '@mercer/shared/util-http-js';
import { QueryStringParameters } from '@mercer/shared/util-http-js';
import { ApiServiceConstants } from './api-constants.service';

@Injectable()
export class ApiEndpointsService {
  private environment;

  constructor(
    // Application Constants
    protected constants: ApiServiceConstants,
    @Inject('environment') environment: any
  ) {
    this.environment = environment;
  }
  /* #region URL CREATOR */
  // URL
  protected createUrl(action: string, isMockAPI = false): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      isMockAPI ? this.constants.API_MOCK_ENDPOINT : 'https://' + this.environment.apiHost + '/api/v1',
      action
    );
    return urlBuilder.toString();
  }
  // URL WITH QUERY PARAMS
  protected createUrlWithQueryParameters(
    action: string,
    queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder('https://' + this.environment.apiHost + '/api/v1', action);
    // Push extra query string params
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
    }
    return urlBuilder.toString();
  }

  // URL WITH PATH VARIABLES
  protected createUrlWithPathVariables(action: string, pathVariables: any[] = []): string {
    let encodedPathVariablesUrl = '';
    // Push extra path variables
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl += `/${encodeURIComponent(pathVariable.toString())}`;
      }
    }
    const urlBuilder: UrlBuilder = new UrlBuilder(
      'https://' + this.environment.apiHost + '/api/v1',
      `${action}${encodedPathVariablesUrl}`
    );
    return urlBuilder.toString();
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class ApiServiceConstants {
  public readonly API_ENDPOINT: string = 'https://devapi.merceradvisors.com/api/v1';
  public readonly API_DEV_ENDPOINT: string = 'https://devapi.merceradvisors.com/api/v1';
  public readonly API_MOCK_ENDPOINT: string = 'https://devapi.merceradvisors.com/api/v1';

  //advisor directory endpoints
  public readonly API_ENDPOINT_ADVISOR_DIRECTORY: string = 'Advisors/CoverageProfile/AdvisorDirectory';
}

import { Routes } from '@angular/router';
import { AdvisorMatchingToolComponent } from './pages/advisor-matching-tool/advisor-matching-tool.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

export const routes: Routes = [
  {
    path: 'advisor-directory',
    loadChildren: () => import('@mercer/advisor-directory/feature-main-ng').then(m => m.featureMainRoutes),
  },
  {
    path: 'advisor-matching-tool',
    component: AdvisorMatchingToolComponent,
    children: [{ path: '', component: AdvisorMatchingToolComponent }],
  },
  {
    path: '',
    redirectTo: 'advisor-directory',
    pathMatch: 'full',
    data: { animation: 'EmptyPage' },
  },
  {
    path: '**',
    component: PageNotFoundComponent, // should redirect to 404 page.
  },
];

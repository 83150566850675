import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { environment } from '../environments/environment';
import {
  ApiAdvisorService,
  ApiHttpService,
  ApiServiceConstants,
} from '@mercer/advisor-directory/data-access-advisors-ng';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    provideHttpClient(withFetch()),
    { provide: 'environment', useValue: environment },
    HttpClient,
    ApiHttpService,
    ApiServiceConstants,
    ApiAdvisorService,
    provideAnimationsAsync(),
  ],
};

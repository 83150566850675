import { AdvisorDirectory, CoverageProfile } from '@mercer/advisor-directory/util-domain-ng';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  public readonly ACCESS_TOKEN_KEY: string = 'access-token'; // bearer acccess token for authenticated calls to Mercer API
  public readonly SELECTED_ADVISOR_PROFILE: string = 'selected-advisor-profile';
  public readonly ADVISOR_DIRECTORY: string = 'advisor-directory';

  constructor() {
    // placeholder
  }

  signOut(): void {
    //window.sessionStorage.clear();
    this.remove(this.ACCESS_TOKEN_KEY);
    this.remove(this.SELECTED_ADVISOR_PROFILE);
    this.remove(this.ADVISOR_DIRECTORY);
  }

  public save(key: string, value: string): void {
    const hashkey = this.stringToHash(key);
    window.sessionStorage.removeItem(hashkey);
    window.sessionStorage.setItem(hashkey, value);
  }

  public get(key: string): string {
    const hashkey = this.stringToHash(key);
    const val = window.sessionStorage.getItem(hashkey);

    if (val !== null) {
      return val;
    }
    return '';
  }

  public remove(key: string): void {
    const hashkey = this.stringToHash(key);
    window.sessionStorage.removeItem(hashkey);
  }

  public isUserLoggedIn(): boolean {
    return !!this.get(this.ACCESS_TOKEN_KEY);
  }

  public get SelectedAdvisorProfile(): CoverageProfile {
    return JSON.parse(this.get(this.SELECTED_ADVISOR_PROFILE)) as CoverageProfile;
  }

  public set SelectedAdvisorProfile(profile: CoverageProfile) {
    this.save(this.SELECTED_ADVISOR_PROFILE, JSON.stringify(profile));
  }

  public clearSelectedAdvisorProfile() {
    this.remove(this.SELECTED_ADVISOR_PROFILE);
  }

  public get AdvisorDirectorySearchText(): string {
    let text = '';
    if (this.hasAdvisorDirectory()) {
      text = this.AdvisorDirectory.searchText;
    }
    return text;
  }

  public set AdvisorDirectorySearchText(searchText: string) {
    if (this.hasAdvisorDirectory()) {
      const ad = this.AdvisorDirectory;
      ad.searchText = searchText;
      this.save(this.ADVISOR_DIRECTORY, JSON.stringify(ad));
    }
  }

  public get AdvisorDirectoryShowOnlyAcceptingClients(): boolean {
    let show = false;
    if (this.hasAdvisorDirectory()) {
      show = this.AdvisorDirectory.showAcceptingClientsOnly;
    }
    return show;
  }

  public set AdvisorDirectoryShowOnlyAcceptingClients(show: boolean) {
    if (this.hasAdvisorDirectory()) {
      const ad = this.AdvisorDirectory;
      ad.showAcceptingClientsOnly = show;
      this.save(this.ADVISOR_DIRECTORY, JSON.stringify(ad));
    }
  }

  public clearAdvisorDirectory(): void {
    this.remove(this.SELECTED_ADVISOR_PROFILE);
    this.remove(this.ADVISOR_DIRECTORY);
  }

  public hasAdvisorDirectory(): boolean {
    return !!this.get(this.ADVISOR_DIRECTORY);
  }

  public get AdvisorDirectory(): AdvisorDirectory {
    return JSON.parse(this.get(this.ADVISOR_DIRECTORY)) as AdvisorDirectory;
  }

  public set AdvisorDirectory(advisorDirectory: AdvisorDirectory) {
    this.save(this.ADVISOR_DIRECTORY, JSON.stringify(advisorDirectory));
  }

  private stringToHash(key: string): string {
    let hash = 0;
    if (key.length == 0) {
      return hash.toString();
    }
    for (let i = 0; i < key.length; i++) {
      const c = key.charCodeAt(i);
      hash = (hash << 5) - hash + c;
      hash = hash & hash;
    }
    return hash.toString();
  }
}

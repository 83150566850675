import { Injectable } from '@angular/core';
import {
  AdvisorDirectory,
  CoverageProfile,
  CoverageProfileFilters,
  ServiceResponse,
} from '@mercer/advisor-directory/util-domain-ng';
import { Observable } from 'rxjs';

import { ApiServiceConstants } from './api-constants.service';
import { ApiEndpointsService } from './api-endpoints.service';
import { ApiHttpService } from './api-http.service';
import { Inject } from '@angular/core';

@Injectable()
export class ApiAdvisorService extends ApiEndpointsService {
  constructor(
    @Inject('environment') environment: any,
    constants: ApiServiceConstants,
    private apiHttpService: ApiHttpService
  ) {
    super(constants, environment);
  }

  public getDirectory(advisorDirectory: AdvisorDirectory): Observable<ServiceResponse<AdvisorDirectory>> {
    return this.apiHttpService.post<ServiceResponse<AdvisorDirectory>>(
      this.createUrl(this.constants.API_ENDPOINT_ADVISOR_DIRECTORY),
      advisorDirectory
    );
  }
}
